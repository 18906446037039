import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import { useState, useEffect } from "react";
import { VEHICLE_MANAGER } from '../components/vehicle-manager';
import EditVehicleModal from '../modals/edit-vehicle-modal';
import ConfirmationModal from '../modals/delete-confirmation-modal';
import { FaTrash, FaEdit } from 'react-icons/fa';

function VehiclesList() {
    const { VEHICLE_FETCH, VEHICLE_DELETE } = VEHICLE_MANAGER();
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 768);

    const columns = [
        {
            name: 'Plate Number',
            selector: row => {
                const plate = String(row.plate_number);
                const middle = plate.slice(1, -1).replace(/./g, '*');
                return `${plate[0]}${middle}${plate.slice(-1)}`;  // Show first and last characters
            },
            sortable: true,
            width: '15%',
        },
        {
            name: 'Owner',
            selector: row => row.username,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Email',
            selector: row => {
                const email = String(row.email);
                const [user, domain] = email.split('@');  // Split email into user and domain
                const obfuscatedUser = user.slice(0, 2) + user.slice(2).replace(/./g, '*');  // Show first 2 characters of user, replace rest with '*'
                return `${obfuscatedUser}@${domain}`;  // Return obfuscated email
            },
            sortable: true,
            width: '20%',
        },
        {
            name: 'Identity',
            selector: row => row.identity,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Contact Number',
            selector: row => {
                const contact = String(row.contact_number);
                const middle = contact.slice(2, -3).replace(/./g, '*');  // Replace the middle part with '*'
                return `${contact.slice(0, 2)}${middle}${contact.slice(-3)}`;
            },
            sortable: true,
            width: '15%',
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="d-flex">
                    <Button variant="info" className="me-2" onClick={() => toggleModal(row.plate_number)}>
                        {mobile ? <FaEdit /> : 'Edit'}
                    </Button>
                    <Button variant="danger" className="ms-2" onClick={() => toggleDeleteModal(row.plate_number)}>
                        {mobile ? <FaTrash /> : 'Delete'}
                    </Button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '20%',
        },
    ];

    const customStyles = {
        table : {
            style : {
                height: '80vh',
            }
        },
        headCells: {
            style: {
                fontWeight: 'bold',  // Make headers bold
                backgroundColor: '#191D80',  // Background color for the headers
                color: '#fff',  // Font color for the headers
                paddingLeft: '2.5%',
                justifyContent: 'center',
            },
        },
        cells: {
            style: {
                justifyContent: 'center',
            }
        }
    };

    //Function for edit modal event
    const toggleModal = (id) => {
        setSelectedVehicle(id);
        setShowModal(!showModal);
    };

    //Function for delete modal event
    const toggleDeleteModal = (id) => {
        setSelectedVehicle(id);
        setDeleteModal(!showDeleteModal);
    };

    //Fetch the vehicles data
    useEffect(() => {
        VEHICLE_FETCH().then(vehicleList => {
            setVehicles(vehicleList);
        });
    }, []);

    return (
        <div className="vehicles-table">
        <DataTable
            title="Vehicle List"
            columns={columns}
            data={vehicles}
            customStyles={customStyles}
            fixedHeader
            pagination
            responsive
            noDataComponent="No registered vehicles to display"
        />

        {showModal &&
            <EditVehicleModal plate_number={ selectedVehicle } 
                              onClose={() => {
                                    setShowModal(false);
                                    VEHICLE_FETCH().then(vehicleList => {
                                        setVehicles(vehicleList);
                                    });    
                               }}/>
        }

        {showDeleteModal &&
                <ConfirmationModal onClose={() => setDeleteModal(false) }
                                   onDelete={ async() => {
                                        setDeleteModal(false);
                                        await VEHICLE_DELETE( selectedVehicle );
                                        VEHICLE_FETCH().then(vehicleList => {
                                            setVehicles(vehicleList);
                                        });  
         }}/>}
        </div>
    )
}

export default VehiclesList;