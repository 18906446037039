import { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { Button } from 'react-bootstrap';
import { VEHICLE_MANAGER } from "../components/vehicle-manager";
import ViewPendingModal from "../modals/view-request-modal";

function PendingList() {
    const [pendings, setPendings] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedPending, setSelectedPending] = useState(null);
    const { PENDING_FETCH } = VEHICLE_MANAGER();

    const columns = [
        {
            name: 'Plate Number',
            selector: row => {
                const plate = String(row.plate_number);
                const middle = plate.slice(1, -1).replace(/./g, '*');
                return `${plate[0]}${middle}${plate.slice(-1)}`;  // Show first and last characters
            },
            sortable: true,
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => {
                const email = String(row.email);
                const [user, domain] = email.split('@');  // Split email into user and domain
                const obfuscatedUser = user.slice(0, 2) + user.slice(2).replace(/./g, '*');  // Show first 2 characters of user, replace rest with '*'
                return `${obfuscatedUser}@${domain}`;  // Return obfuscated email
            },
            sortable: true,
        },
        {
            name: 'Identity',
            selector: row => row.identity,
            sortable: true,
        },
        {
            name: 'Contact Number',
            selector: row => {
                const contact = String(row.contact_number);
                const middle = contact.slice(2, -3).replace(/./g, '*');  // Replace the middle part with '*'
                return `${contact.slice(0, 2)}${middle}${contact.slice(-3)}`;
            },
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <Button variant="info" className="me-2" onClick={() => toggleModal(row.plate_number)}>
                        View
                    </Button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const customStyles = {
        table : {
            style : {
                height: '85vh',
            }
        },
        headCells: {
            style: {
                fontWeight: 'bold',  // Make headers bold
                backgroundColor: '#191D80',  // Background color for the headers
                color: '#fff',  // Font color for the headers
                paddingLeft: '2.5%',
                justifyContent: 'center',
            },
        },
        cells: {
            style: {
                justifyContent: 'center',
            }
        }
    };

    //Function for view modal event
    const toggleModal = (plate_number) => {
        setSelectedPending(plate_number);
        setShowModal(!showModal);
    };

    //Fetch the pending lists
    useEffect(() => {
        PENDING_FETCH().then(pendingList => {
            setPendings(pendingList);
        });
    }, []);

    return (
        <div className="pending-table">
            <DataTable
                title="Pending Request"
                columns={columns}
                data={pendings}
                customStyles={customStyles}
                fixedHeader
                pagination
                responsive
                noDataComponent="There are currently no request to accept"
            />
            
            {showModal && 
                <ViewPendingModal plate_number={ selectedPending }
                                  onClose={() => {
                                      setShowModal(false);
                                      PENDING_FETCH().then(pendingList => {
                                          setPendings(pendingList);
                                      });    
                                  }}/>
            }
        </div>
    )
}

export default PendingList;