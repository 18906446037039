import BACKEND_API from "../backend-api/api";
import { TOAST_SUCCESS, TOAST_FAIL } from "./toasters";

export const LOG_RECORDS_MANAGER = () => {

    //Function for adding a record to student logs
    const ADD_RECORD_LOG = async(plate_number) => {
        await BACKEND_API.post("/add-record-log", {
            plate_number
        })
    };

    //Function for fetching all vehicle logs
    const VEHICLE_LOGS_FETCH = async() => {
        const response = await BACKEND_API.get("/fetch-vehicle-logs");
        return response.data.vehicleLogsList;
    };

    //Function for fetching all archived logs
    const ARCHIVED_LOGS_FETCH = async() => {
        const response = await BACKEND_API.get("/fetch-archived-logs");
        return response.data.archiveLogsList;
    }

    //Function for archiving the logs
    const ARCHIVE_VEHICLE_LOGS = async(filter_date) => {
        await BACKEND_API.post("/archive-log", {
            filter_date
        }).then(response => {
            if(response.data.message === "success") {
                TOAST_SUCCESS("The filtered logs has been archived");
            } else {
                TOAST_FAIL("Try again");
            }
        })
    };

    //Function for restoring the logs
    const RESTORE_VEHICLE_LOGS = async(filter_date) => {
        await BACKEND_API.post("/restore-logs", {
            filter_date
        }).then(response => {
            if(response.data.message === "success") {
                TOAST_SUCCESS("The records have been restored");
            } else {
                TOAST_FAIL("Try Again");
            }
        })
    };

    //Function to delete the logs
    const DELETE_ARCHIVE_LOGS = async(filter_date) => {
        await BACKEND_API.post("/delete-logs", {
            filter_date
        }).then(response => {
            if(response.data.message === "success") {
                TOAST_SUCCESS("The logs has been deleted");
            } else {
                TOAST_FAIL("Try Again");
            }
        })
    };

    return { ADD_RECORD_LOG, VEHICLE_LOGS_FETCH, 
             ARCHIVE_VEHICLE_LOGS, ARCHIVED_LOGS_FETCH, 
             RESTORE_VEHICLE_LOGS, DELETE_ARCHIVE_LOGS };
}