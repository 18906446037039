import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react";
import { USER_AUTHENTICATE } from "../components/user-manager";
import { Button } from 'react-bootstrap';
import EditUserModal from '../modals/edit-user-modal';
import ConfirmationModal from '../modals/delete-confirmation-modal';
import { FaTrash, FaEdit } from 'react-icons/fa';

function UsersList() {
    const { USER_FETCH, USER_DELETE } = USER_AUTHENTICATE();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 768);

    const columns = [
        {
            name: 'ID Number',
            selector: row => {
                const id = String(row.id_number);
                return `${id.slice(0, -1).replace(/\d/g, '*')}${id.slice(-3)}`;
            },
            sortable: true,
            width: '20%',
        },
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
            width: '20%',
        },
        {
            name: 'Email',
            selector: row => {
                const email = String(row.email);
                const [user, domain] = email.split('@');  // Split email into user and domain
                const obfuscatedUser = user.slice(0, 2) + user.slice(2).replace(/./g, '*');  // Show first 2 characters of user, replace rest with '*'
                return `${obfuscatedUser}@${domain}`;  // Return obfuscated email
            },
            sortable: true,
            width: '20%',
        },
        {
            name: 'User Type',
            selector: row => row.user_type,
            sortable: true,
            width: '20%',
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="d-flex">
                    <Button variant="info" className="me-2" onClick={() => toggleModal(row.id_number)}>
                        {mobile ? <FaEdit /> : 'Edit'}
                    </Button>
                    <Button variant="danger" className="ms-2" onClick={() => toggleDeleteModal(row.id_number)}>
                        {mobile ? <FaTrash /> : 'Delete'}
                    </Button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '20%',
        },
    ];

    const customStyles = {
        table : {
            style : {
                height: '80vh',
            }
        },
        headCells: {
            style: {
                fontWeight: 'bold',  // Make headers bold
                backgroundColor: '#191D80',  // Background color for the headers
                color: '#fff',  // Font color for the headers
                paddingLeft: '2.5%',
                justifyContent: 'center',
            },
        },
        cells: {
            style: {
                justifyContent: 'center',
            }
        }
    };

    //Function for edit modal event
    const toggleModal = (id_number) => {
        setSelectedUser(id_number);
        setShowModal(!showModal);
    };

    //Function for delete modal event
    const toggleDeleteModal = (id_number) => {
        setSelectedUser(id_number);
        setDeleteModal(!showDeleteModal);
    };


    useEffect(() => {
        const handleResize = () => setMobile(window.innerWidth <= 768);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    //Fetch the users data
    useEffect(() => {
        USER_FETCH().then(userList => {
            setUsers(userList);
        });
    }, []);

    return (
        <div className="users-table">
            <DataTable
                title="Users List"
                columns={columns}
                data={users}
                customStyles={customStyles}
                fixedHeader
                pagination
                responsive
                noDataComponent="No registered users to display"
            />

            {showModal &&
                <EditUserModal id_number={ selectedUser } 
                               onClose={() => {
                                    setShowModal(false);
                                    USER_FETCH().then(userList => {
                                        setUsers(userList);
                                    });    
                               }}/>
            }

            {showDeleteModal &&
                <ConfirmationModal onClose={() => setDeleteModal(false) }   
                                   onDelete={ async() => {
                                        setDeleteModal(false);
                                        await USER_DELETE( selectedUser );
                                        USER_FETCH().then(userList => {
                                            setUsers(userList);
                                        });  
                }}/>}
        </div>
    )
}

export default UsersList;