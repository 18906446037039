import BACKEND_API from "../backend-api/api";
import { TOAST_SUCCESS, TOAST_FAIL } from "./toasters";
import emailjs from '@emailjs/browser';

export const VEHICLE_MANAGER = () => {

    //Function for Registering a Vehicle (for admin)
    const VEHICLE_REGISTER = async(plate_number, username, identity, email, contact_number) => {
        await BACKEND_API.post("/vehicle-register", {
            plate_number,
            username,
            identity,
            email,
            contact_number,
        }).then(response => {
            if(response.data.message === "success") {
                TOAST_SUCCESS("Vehicle is now registered");
            }
        }).catch(error => {
            if(error.response && error.response.data.errors) {
                const errors = error.response.data.errors;

                if (errors.plate_number) {
                    TOAST_FAIL("The plate number is already existing");
                } else if (errors.email) {
                    TOAST_FAIL("The email is already used");
                } else {
                    TOAST_FAIL("An error occurred. Please try again.");
                }
            }
        })
    };

    //Function for Registering a Vehicle (for visitors)
    const VEHICLE_REGISTER_1 = async(formData) => {
        await BACKEND_API.post("/vehicle-register-request", formData, {
            headers: {
                'Content-Type': 'multipart/form-data' // Important for file uploads
            }
        }).then(response => {
            if(response.data.message === "success") {
                TOAST_SUCCESS("Request has been sent");
            } else {
                TOAST_FAIL("Try Again");
            }
        }).catch(error => {
            if(error.response && error.response.data.errors) {
                const errors = error.response.data.errors;

                if(errors.plate_number) {
                    TOAST_FAIL("The plate number is already existing");
                } else if(errors.email) {
                    TOAST_FAIL("The email is already used");
                } else if(errors.valid_id_url) {
                    TOAST_FAIL("Put an image file");
                } else if(errors.license_doc_url) {
                    TOAST_FAIL("Put an image file");
                } else {
                    TOAST_FAIL("An error occurred. Please try again.");
                }
            }
        })
    };

    //Function for fetching all vehicles
    const VEHICLE_FETCH = async() => {
        const response = await BACKEND_API.get("/fetch-vehicles");
        return response.data.vehicleList;
    }

    //Function for fetching a specific vehicle using the id
    const VEHICLE_INFO_FETCH = async(plate_number) => {
        const response = await BACKEND_API.get(`/fetch-vehicle/${ plate_number }`);
        return response.data.vehicleDetails;
    }

    //Function for fetching pending request for vehicles
    const PENDING_FETCH = async() => {
        const response = await BACKEND_API.get("/fetch-requests");
        return response.data.pendingList;
    }

    //Function for fetching request details for a vehicle
    const FETCH_PENDING_DETAILS = async(plate_number) => {
        const response = await BACKEND_API.get(`/fetch-pending/${ plate_number }`);
        return response.data.pendingDetails;
    }

    //Function for updating the details of a vehicle
    const VEHICLE_UPDATE = async(plate_number, username, identity) => {
        await BACKEND_API.post("/update-vehicle", {
            plate_number,
            username,
            identity,
        }).then(response => {
            if(response.data.message === "success") {
                TOAST_SUCCESS("Vehicle datails are now updated");
            } else {
                TOAST_FAIL("Update Process Fail");
            }
        });
    }

    //Function for deleting a vehicle
    const VEHICLE_DELETE = async(plate_number) => {
        await BACKEND_API.post("/delete-vehicle", {
            plate_number,
        }).then(response => {
            if(response.data.message === "success") {
                TOAST_SUCCESS("Vehicle has been deleted");
            } else {
                TOAST_FAIL("Delete Process Fail");
            }
        });
    }

    // Function to accept the request of vehicle owner
    const ACCEPT_REQUEST = async(plate_number, email, username) => {
        await BACKEND_API.post("/accept-request", {
            plate_number,
            email
        }).then(response => {
            if(response.data.message === "success") {
                TOAST_SUCCESS("Request Accepted");

                // EmailJS service ID, template ID, and Public Key
                const service_id = "service_v7wvlps";
                const template_id = "template_cozzdrh";
                const public_key = "xtd9GjZ6KlGEn4p2f";

                const template_params = {
                    to_name: username,
                    to_email: email,
                    from_name: "LNU-VMS",
                    message: "This email is to inform you that your request to register in our system has been accepted."
                };

                emailjs.send(service_id, template_id, template_params, public_key);
            } else {
                TOAST_FAIL("Process Fail");
            }
        });
    }

    // Function to deny the request of the vehicle owner
    const DENY_REQUEST = async(plate_number, email, username) => {
        await BACKEND_API.post("/delete-vehicle", {
            plate_number,
        }).then(response => {
            if(response.data.message === "success") {
                TOAST_SUCCESS("Request Denied");
                
                // EmailJS service ID, template ID, and Public Key
                const service_id = "service_v7wvlps";
                const template_id = "template_cozzdrh";
                const public_key = "xtd9GjZ6KlGEn4p2f";

                const template_params = {
                    to_name: username,
                    to_email: email,
                    from_name: "LNU-VMS",
                    message: "This email is to inform you that your request to register in our system has been denied."
                };

                emailjs.send(service_id, template_id, template_params, public_key);
            } else {
                TOAST_FAIL("Process Fail");
            }
        });
    }

    return { VEHICLE_REGISTER, VEHICLE_REGISTER_1, 
             VEHICLE_FETCH, VEHICLE_INFO_FETCH, 
             PENDING_FETCH, VEHICLE_UPDATE, 
             FETCH_PENDING_DETAILS, VEHICLE_DELETE,
             ACCEPT_REQUEST, DENY_REQUEST };
}