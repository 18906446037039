import { Modal, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { VEHICLE_MANAGER } from '../components/vehicle-manager';

function EditVehicleModal({ plate_number, onClose }) {
    const { VEHICLE_INFO_FETCH, VEHICLE_UPDATE } = VEHICLE_MANAGER();
    const [show, setShow] = useState(true);
    const [state, setState] = useState({
        plate_number: '',
        username: '',
        email: '',
        contact_number: '',
        identity: '',
    });

    //Fetch the vehicle details
    useEffect(() => {
        VEHICLE_INFO_FETCH(plate_number).then(vehicleDetails => {
            setState(vehicleDetails);
        });
    }, [plate_number]);

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    const handleChange = (event) => {
        const field = event.target.name;
        setState((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    const handleUpdate = async(event) => {
        event.preventDefault();

        await VEHICLE_UPDATE(state.plate_number, 
                             state.username, 
                             state.identity);
        onClose();
        setShow(false);
    }

    return (
        <Modal show={ show } onHide={ handleClose } centered>
            <Modal.Header>
                <Modal.Title className="fw-bold text-white">Edit Vehicle</Modal.Title>
            </Modal.Header>

            <form onSubmit={ handleUpdate }>
            <Modal.Body>
                <div className="mb-4">
                    <label className="edit-modal-label">Plate Number :</label>
                        <input type="text"
                               name="plate_number"
                               className="border-0 mt-3 rounded-3 edit-modal-textbox p-3"
                               placeholder="Plate Number"
                               id="plate_number"
                               value={ state.plate_number }
                               onChange={ handleChange }
                               required/>     
                </div>

                <div className="mb-4">
                    <label className="edit-modal-label">Name :</label>
                        <input type="text"
                               name="username"
                               className="border-0 mt-3 rounded-3 edit-modal-textbox p-3"
                               placeholder="Owner Name"
                               id="username"
                               value={ state.username }
                               onChange={ handleChange }
                               required/>
                </div>

                <div className="mb-4">
                    <label className="edit-modal-label">Email :</label>
                        <input type="email"
                               name="email"
                               className="border-0 mt-3 rounded-3 edit-modal-textbox p-3"
                               placeholder="Email"
                               id="email"
                               value={ state.email }
                               onChange={ handleChange }
                               required/>
                </div>

                <div className="mb-4">
                    <label className="edit-modal-label">Contact Number :</label>
                        <input type="number"
                               name="contact_number"
                               className="border-0 mt-3 rounded-3 edit-modal-textbox p-3"
                               placeholder="Contact Number"
                               id="contact_number"
                               value={ state.contact_number }
                               onChange={ handleChange }
                               required/>
                </div>

                <div className="mb-4">
                    <label className="edit-modal-label">Identity :</label>
                        <select className="border-0 mt-3 rounded-3 edit-modal-textbox p-3" 
                                name="identity" 
                                value={ state.identity } 
                                onChange={ handleChange } 
                                required>
                            <option value="" defaultValue disabled>Select your Identity</option>
                            <option value="Employee">Employee</option>
                            <option value="Visitor">Visitor</option>
                        </select>
                </div>
       
            </Modal.Body>

            <Modal.Footer>
                <Button variant="info" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="success" type="submit">
                    Save Changes
                </Button>
            </Modal.Footer>
            </form>
        </Modal>
    )
}

export default EditVehicleModal;