import Sidenav from "../templates/sidenav-template";
import { ToastContainer } from "react-toastify";
import VehicleLogList from "../data-tables/vehicle-logs-table";

function LogPage() {

    return (
        <div className="d-flex column log-page">
            <ToastContainer />
            <Sidenav />
            <VehicleLogList />
        </div>
    )
}

export default LogPage;