import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import LoginPage from './pages/login-page';
import RegisterPage from './pages/register-page';
import RegisterListPage from './pages/register-list-page';
import HomePage from './pages/home-page';
import RegisterVehiclePage from './pages/register-vehicle-page';
import LogPage from './pages/log-page';
import PendingPage from './pages/pending-list-page';
import ArchivePage from './pages/archive-page';
import NotFoundPage from './pages/not-found-page';

// Wrapper component to check for authentication
function RequireAuth({ children }) {
  const token = localStorage.getItem('token'); // Assuming your token is stored under 'token' key
  const location = useLocation();

  if (!token) {
    // Redirect to login page if no token is found
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Render protected routes if token is present
  return children;
}

// Wrapper component to ensure user is not authenticated (not logged in)
function RequireUnauth({ children }) {
  const token = localStorage.getItem('token'); // Assuming your token is stored under 'token' key

  if (token) {
    // Redirect to home (or any other suitable page) if token is found
    return <Navigate to="/home" replace />;
  }

  // Render unauthenticated routes (like login, register) if no token is present
  return children;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        
        {/* Public Routes */}
        <Route 
          path="/login" 
          element={
            <RequireUnauth>
              <LoginPage />
            </RequireUnauth>
          } 
        />
        <Route 
          path="/register-vehicle" 
          element={
            <RequireUnauth>
              <RegisterVehiclePage />
            </RequireUnauth>
          } 
        />
        
        {/* Protected Routes */}
        <Route 
          path="/home" 
          element={
            <RequireAuth>
              <HomePage />
            </RequireAuth>
          } 
        />
        <Route 
          path="/register-list" 
          element={
            <RequireAuth>
              <RegisterListPage />
            </RequireAuth>
          } 
        />
        <Route 
          path="/register" 
          element={
            <RequireAuth>
              <RegisterPage />
            </RequireAuth>
          } 
        />
        <Route 
          path="/logs" 
          element={
            <RequireAuth>
              <LogPage />
            </RequireAuth>
          } 
        />
        <Route 
          path="/archive-logs"
          element={
            <RequireAuth>
              <ArchivePage />
            </RequireAuth>
          }
        />
        <Route 
          path="/pending-requests" 
          element={
            <RequireAuth>
              <PendingPage />
            </RequireAuth>
          } 
        />
        <Route path="*" element={<NotFoundPage />}/>
      </Routes>
    </Router>
  );
}

export default App;
