import axios from "axios";

export const BACKEND_API = axios.create({
    withCredentials: true,
    baseURL: "https://api.lnu-vms.online/api"
});

export function SetAuthToken(token) {
    if(token) {
        BACKEND_API.defaults.headers.common['Authorization'] = `Bearer ${ token }`;
        console.log("Authorization header set:", BACKEND_API.defaults.headers.common['Authorization']);
    } else {
        delete BACKEND_API.defaults.headers.common['Authorization'];
    }
}

export default BACKEND_API;