import { ToastContainer } from "react-toastify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { VEHICLE_MANAGER } from "../components/vehicle-manager";
import { TOAST_FAIL } from "../components/toasters";

function RegisterVehiclePage() {
    const [loader, setLoader] = useState("Register");
    const { VEHICLE_REGISTER_1 } = VEHICLE_MANAGER();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [mobile, setMobile] = useState(window.innerWidth <= 768);
    const navigate = useNavigate();
    const [state, setState] = useState({
        plate_number: '',
        username: '',
        valid_id_url: 'null',
        license_doc_url: 'null',
        identity: '',
        email: '',
        contact_number: '',
        purpose: '',
    });

    //Function for change state event
    const handleChange = (event) => {
        const field = event.target.name;
        setState((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    // Handle image input change
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if(file && file.type.startsWith('image/')) {
            if (file) {
                setState((prevState) => ({
                    ...prevState,
                    valid_id_url: file,
                }));
                setImagePreviewUrl(URL.createObjectURL(file));
            } else {
                setImagePreviewUrl(null);
            }
        } else {
            TOAST_FAIL("Please select a valid image file");
            setState({valid_id_url: null});
        }
    };

    // Handle document input change
    const handleDocumentChange = (event) => {
        const file = event.target.files[0];
        if(file && file.type.startsWith('image/')) {
            if (file) {
                setState((prevState) => ({
                    ...prevState,
                    license_doc_url: file,
                }));
            }
        } else {
            TOAST_FAIL("Please select a valid image file");
            setState({license_doc_url: null});
        }
    };

    //Function for Login navigate event
    const handleNavigate = (event) => {
        event.preventDefault();
        navigate("/login");
    }

    //Function for Register event
    const handleSubmit = async(event) => {
        event.preventDefault();
        setLoader("Registering...");
    
        const formData = new FormData();
        formData.append('plate_number', state.plate_number);
        formData.append('username', state.username);
        formData.append('identity', state.identity);
        formData.append('email', state.email);
        formData.append('purpose', state.purpose);
        formData.append('contact_number', state.contact_number);
        formData.append('valid_id_url', state.valid_id_url);
        formData.append('license_doc_url', state.license_doc_url);
    
        await VEHICLE_REGISTER_1(formData);
    
        // Reset form fields after submission
        setState({
            plate_number: '',
            username: '',
            email: '',
            contact_number: '',
            identity: '',
            purpose: '',
            valid_id_url: null,
            license_doc_url: null,
        });
        setImagePreviewUrl(null);
        document.querySelector('input[name="valid_id_url"]').value = '';
        document.querySelector('input[name="license_doc_url"]').value = '';
        setLoader("Register");
    };

    return (
        <div className="loginBackground d-flex justify-content-center align-items-center">
            <ToastContainer />

            <form onSubmit={ handleSubmit } className="d-flex row align-content-center register-vehicle-container justify-content-center bg-white mb-5 p-5 rounded-5">
            <h1 className="text-center fw-semibold mb-4">Register Vehicle</h1>
            <p className="text-center register-p">Register to gain access in the University</p>
            <hr />

            <div className="d-flex column">
                <div className="d-flex row">
                <input type="text" 
                       name="plate_number"
                       className="register-textbox border-0 mt-3 rounded-3"
                       placeholder="Plate Number"
                       id="plate_number"
                       maxLength={ 8 }
                       value={ state.plate_number }
                       onChange={ handleChange } 
                       required/>

                <input type="text" 
                       name="username"
                       maxLength={ 16 }
                       className="register-textbox border-0 mt-3 rounded-3"
                       placeholder="Owner Name"
                       id="username"
                       value={ state.username }
                       onChange={ handleChange } 
                       required/>
                
                <input type="email"
                       name="email"
                       className="register-textbox border-0 mt-3 rounded-3"
                       placeholder="Email"
                       id="email"
                       value={ state.email }
                       onChange={ handleChange }
                       required/>
                
                <input type="number"
                       name="contact_number"
                       className="register-textbox border-0 mt-3 rounded-3"
                       placeholder="Contact Number"
                       id="contact_number"
                       value={ state.contact_number }
                       onChange={ handleChange }
                       maxLength={11}
                       onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
                       required/>

                <select className="register-textbox border-0 mt-3 rounded-3" name="identity" value={ state.identity } onChange={ handleChange } required>
                    <option value="" defaultValue disabled>Select your Identity</option>
                    <option value="Employee">Employee</option>
                    <option value="Visitor">Visitor</option>
                </select>

                <select className="register-textbox border-0 mt-3 rounded-3" name="purpose" value={ state.purpose } onChange={ handleChange } required>
                    <option value="" defaultValue disabled>Select your purpose</option>
                    <option value="Official Business">Official Business</option>
                    <option value="Guest Lecture">Guest Lecture</option>
                    <option value="Job Interview">Job Interview</option>
                    <option value="Student Affairs">Student Affairs</option>
                    <option value="Research Collaboration">Research Collaboration</option>
                    <option value="Facility Maintenance">Facility Maintenance</option>
                    <option value="Conference/Seminar">Conference/Seminar</option>
                    <option value="Alumni Visit">Alumni Visit</option>
                    <option value="Event Participation">Event Participation</option>
                    <option value="Campus Tour">Campus Tour</option>
                    <option value="Vendor Delivery">Vendor Delivery</option>
                    <option value="Personal Visit">Personal Visit</option>
                    <option value="Library Access">Library Access</option>
                    <option value="Meeting with Faculty">Meeting with Faculty</option>
                    <option value="Parent/Guardian Visit">Parent/Guardian Visit</option>
                    <option value="Student Counseling">Student Counseling</option>
                    <option value="Health Services">Health Services</option>
                    <option value="Financial Aid Inquiry">Financial Aid Inquiry</option>
                    <option value="Security Clearance">Security Clearance</option>
                    <option value="Project Presentation">Project Presentation</option>
                    <option value="Cultural/Arts Visit">Cultural/Arts Visit</option>
                    <option value="Sports Event Participation">Sports Event Participation</option>
                    <option value="Admissions Inquiry">Admissions Inquiry</option>
                    <option value="Dormitory Visit">Dormitory Visit</option>
                    <option value="IT Support">IT Support</option>
                    <option value="Student Registration">Student Registration</option>
                    <option value="Career Fair">Career Fair</option>
                    <option value="Volunteer Activity">Volunteer Activity</option>
                    <option value="Public Lecture">Public Lecture</option>
                </select>
                
                <label className="mt-2 image-label">Choose Valid ID image :</label>
                <input type="file"
                       name="valid_id_url"
                       className="image-textbox border-0 mt-3 rounded-3"
                       onChange={ handleImageChange }
                       accept="image/*"
                       required/>

                <label className="mt-2 image-label">Official Reciept/Certificate of Registration :</label>
                    <input type="file"
                           name="license_doc_url"
                           className="image-textbox border-0 mt-3 rounded-3"
                           onChange={ handleDocumentChange }
                           accept="image/*"
                           required/>
                           
                </div>

                {!mobile ? (
                    <div>
                        {imagePreviewUrl ? (
                            <img 
                                src={ imagePreviewUrl }
                                alt="Valid ID"
                                className="rounded text-center image-preview ms-5"
                        />
                        ) : (
                            <div className="ms-5 text-muted image-preview d-flex justify-content-center align-items-center">
                                <p>No image selected</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div></div>
                )}

            </div>

                <div className="d-flex justify-content-evenly">
                    <button type="submit"
                            className="register-button border-0 mt-5 rounded-5 text-white"
                            disabled={loader === "Registering..."}>
                                {loader}
                            </button>

                    <button 
                        className="register-button border-0 mt-5 rounded-5 text-white"
                        onClick={ handleNavigate }
                    >Go Back</button>
                </div>

            </form>
        </div>
    )
}

export default RegisterVehiclePage;