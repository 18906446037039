import BACKEND_API from "../backend-api/api";
import { TOAST_SUCCESS, TOAST_FAIL } from "./toasters";

export const CAMERA_MANAGER = () => {

    // Function to register a camera
    const CAMERA_REGISTER = async(camera_name, rtsp_url, location, camera_type) => {
        await BACKEND_API.post("/add-camera", {
            camera_name,
            rtsp_url,
            location,
            camera_type,
        }).then(response => {
            if(response.data.message === "success") {
                TOAST_SUCCESS("Camera is now registered");
            }
        }).catch(error => {
            if(error.response && error.response.data.errors) {
                const errors = error.response.data.errors;

                if (errors.camera_name) {
                    TOAST_FAIL("The name is already used");
                } else if (errors.rtsp_url) {
                    TOAST_FAIL("The link is already existing");
                } else {
                    TOAST_FAIL("An error occurred. Please try again.");
                }
            }
        })
    };

    //Function for fetching all cameras
    const CAMERA_FETCH = async() => {
        const response = await BACKEND_API.get("fetch-cameras");
        return response.data.cameraLists;
    };

    //Function for fetching a specific camera using the id
    const CAMERA_INFO_FETCH = async(id) => {
        const response = await BACKEND_API.get(`/fetch-camera/${ id }`);
        return response.data.cameraDetails;
    }

    //Function for updating the details of a camera
    const CAMERA_UPDATE = async(id, camera_name, rtsp_url, location, camera_type) => {
        await BACKEND_API.post("/update-camera", {
            id,
            camera_name,
            rtsp_url,
            location,
            camera_type,
        }).then(response => {
            if(response.data.message === "success") {
                TOAST_SUCCESS("Camera datails are now updated");
            } else {
                TOAST_FAIL("Update Process Fail");
            }
        });
    }

    //Function for deleting a user
    const CAMERA_DELETE = async(id) => {
        await BACKEND_API.post("/delete-camera", {
            id,
        }).then(response => {
            if(response.data.message === "success") {
                TOAST_SUCCESS("Camera has been deleted");
            } else {
                TOAST_FAIL("Delete Process Fail");
            }
        });
    }

    return { CAMERA_REGISTER, CAMERA_FETCH, CAMERA_INFO_FETCH, CAMERA_UPDATE, CAMERA_DELETE };
}