import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react";
import { Button } from 'react-bootstrap';
import { CAMERA_MANAGER } from '../components/camera-manager';
import ConfirmationModal from '../modals/delete-confirmation-modal';
import EditCameraModal from '../modals/edit-camera-modal';
import { FaTrash, FaEdit } from 'react-icons/fa';

function CameraList() {
    const { CAMERA_FETCH, CAMERA_DELETE } = CAMERA_MANAGER();
    const [cameras, setCameras] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth <= 768);

    const columns = [
        {
            name: 'Number',
            selector: row => row.id,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Camera Name',
            selector: row => row.camera_name,
            sortable: true,
            width: '20%',
        },
        {
            name: 'RTSP URL',
            selector: row => {
                const url = String(row.rtsp_url);  // Convert rtsp_url to a string
                const middle = url.slice(8, -30).replace(/./g, '*'); 
                return `${url.slice(0, 8)}${middle}${url.slice(-9)}`;
            },
            sortable: true,
            width: '20%',
        },
        {
            name: 'Location',
            selector: row => row.location,
            sortable: true,
            width: '20%',
            
        },
        {
            name: 'Actions',
            cell: row => (
                <div className="d-flex">
                    <Button variant="info" className="me-2" onClick={() => toggleModal(row.id)}>
                        {mobile ? <FaEdit /> : 'Edit'}
                    </Button>
                    <Button variant="danger" className="ms-2" onClick={() => toggleDeleteModal(row.id)}>
                        {mobile ? <FaTrash /> : 'Delete'}
                    </Button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '30%',
        },
    ];

    const customStyles = {
        table : {
            style : {
                height: '80vh',
            }
        },
        headCells: {
            style: {
                fontWeight: 'bold',  // Make headers bold
                backgroundColor: '#191D80',  // Background color for the headers
                color: '#fff',  // Font color for the headers
                paddingLeft: '2.5%',
                justifyContent: 'center',
            },
        },
        cells: {
            style: {
                justifyContent: 'center',
            }
        }
    };

    //Function for edit modal event
    const toggleModal = (id) => {
        setSelectedCamera(id);
        setShowModal(!showModal);
    };

    //Function for delete modal event
    const toggleDeleteModal = (id) => {
        setSelectedCamera(id);
        setDeleteModal(!showDeleteModal);
    };

    //Fetch the camera data
    useEffect(() => {
        CAMERA_FETCH().then(cameraLists => {
            setCameras(cameraLists);
        });
    }, []);

    return (
        <div className="cameras-table">
            <DataTable
                title="Camera List"
                columns={columns}
                data={cameras}
                customStyles={customStyles}
                fixedHeader
                pagination
                responsive
                noDataComponent="No registered cameras to display"
            />

            {showModal &&
            <EditCameraModal id={ selectedCamera } 
                               onClose={() => {
                                    setShowModal(false);
                                    CAMERA_FETCH().then(cameraLists => {
                                        setCameras(cameraLists);
                                    });    
            }}/>}

            {showDeleteModal &&
            <ConfirmationModal onDelete={ async() => {
                                    setDeleteModal(false);
                                    await CAMERA_DELETE( selectedCamera );
                                    CAMERA_FETCH().then(cameraLists => {
                                        setCameras(cameraLists);
                                    });  
            }}/>}
        </div>
    )
}

export default CameraList;