import DataTable from 'react-data-table-component';
import { useState, useEffect } from "react";
import { LOG_RECORDS_MANAGER } from '../components/log-records-manager';
import { TOAST_FAIL } from '../components/toasters';

function ArchiveLogList({ onRefresh }) {
    const [archiveLogs, setArchiveLogs] = useState([]);
    const [filterText, setFilterText] = useState("");  // State for the filter text
    const [loader1, setLoader1] = useState("Delete Forever");
    const [loader2, setLoader2] = useState("Restore");
    const { ARCHIVED_LOGS_FETCH, RESTORE_VEHICLE_LOGS, DELETE_ARCHIVE_LOGS } = LOG_RECORDS_MANAGER();

    const columns = [
        {
            name: 'Log ID',
            selector: row => row.log_id,
            sortable: true,
        },
        {
            name: 'Owner Name',
            selector: row => row.vehicle.username,
            sortable: true,
        },
        {
            name: 'Plate Number',
            selector: row => {
                const plate = String(row.plate_number);
                const middle = plate.slice(1, -1).replace(/./g, '*');
                return `${plate[0]}${middle}${plate.slice(-1)}`;  // Show first and last characters
            },
            sortable: true,
        },
        {
            name: 'Identity',
            selector: row => row.vehicle.identity,
            sortable: true,
        },
        {
            name: "Time In",
            selector: row => formatDateTime(row.time_in),
            sortable: true,
        },
        {
            name: "Time Out",
            selector: row => formatDateTime(row.time_out),
            sortable: true,
        }
    ];

    const customStyles = {
        table : {
            style : {
                height: '85vh',
            }
        },
        headCells: {
            style: {
                fontWeight: 'bold',  // Make headers bold
                backgroundColor: '#191D80',  // Background color for the headers
                color: '#fff',  // Font color for the headers
                paddingLeft: '2.5%',
                justifyContent: 'center',
            },
        },
        cells: {
            style: {
                justifyContent: 'center',
            }
        }
    };

    // Helper function to format date
    const formatDateTime = (dateString) => {
        if(!dateString) {
            return "N/A";
        }

        const options = { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric', 
            hour: 'numeric', 
            minute: 'numeric', 
            hour12: true 
        };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    // Fetch all vehicle logs data
    const fetchData = async () => {
        const archiveLogList = await ARCHIVED_LOGS_FETCH();
        setArchiveLogs(archiveLogList);
    };

    useEffect(() => {
        fetchData();
    }, [onRefresh]);

    // Filtered data based on input
    const filteredLogs = archiveLogs.filter(log => {
        if (!filterText) return true;

        // Get the year and month from the filter text (format is YYYY-MM)
        const [month, year] = filterText.split('-');
        const formattedFilterMonthYear = new Date(`${month}-${year}-01`).toLocaleDateString('en-US', { 
            year: 'numeric', month: 'long' 
        });

        // Helper function to format date to "Month YYYY"
        const formatDateToMonthYear = (dateString) => {
            if (!dateString) return '';
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
        };

        const timeInMonthYear = formatDateToMonthYear(log.time_in);
        const timeOutMonthYear = formatDateToMonthYear(log.time_out);

        // Filter based only on time_in or time_out matching the month and year
        return timeInMonthYear === formattedFilterMonthYear || timeOutMonthYear === formattedFilterMonthYear;
    });

    const submit = async(event) => {
        event.preventDefault();
        setLoader1("Deleting...");

        if(filterText === "") {
            TOAST_FAIL("Please filter first the log record you want to archive");
        } else {
            await DELETE_ARCHIVE_LOGS(filterText);    
        }
        setLoader1("Delete Forever");
        fetchData();
    }

    const restore = async(event) => {
        event.preventDefault();
        setLoader2("Restoring...");

        if(filterText === "") {
            TOAST_FAIL("Please filter first the log record you want to archive");
        } else {
            await RESTORE_VEHICLE_LOGS(filterText);
        }
        setLoader2("Restore");
        fetchData();
    }

    return (
        <div className="logs-table">
            <DataTable
                columns={columns}
                data={filteredLogs}  // Use the filtered data
                customStyles={customStyles}
                fixedHeader
                pagination
                responsive
                subHeader
                subHeaderComponent={(
                    <div className="d-flex justify-content-between w-100 align-items-center">
                        <h5 className="fw-bold">Archived Logs</h5>

                        <div className="d-flex align-items-center">
                            <button
                                className="btn btn-success me-2"
                                onClick={ restore }
                                disabled={loader2 === "Restoring..."}>{ loader2 }</button>
                            <button 
                                className="btn btn-danger me-3" 
                                onClick={ submit }
                                disabled={loader1 === "Archiving..."}>{ loader1 }</button>
                            <input
                                type="month"
                                className="border-2 p-1 rounded-3 bg-transparent"
                                placeholder="Filter logs"
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}  // Automatically filter data
                            />
                        </div>

                    </div>
                )}
            />
        </div>
    );

}

export default ArchiveLogList;