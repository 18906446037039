
function NotFoundPage() {
    return (
        <div className="not-found-page d-flex justify-content-center align-items-center">
            <h1 className="fw-bold text-white">Page Not Found</h1>
        </div>
    )

}

export default NotFoundPage;