import Sidenav from "../templates/sidenav-template";
import { ToastContainer } from "react-toastify";
import PendingList from "../data-tables/pending-table";

function PendingPage() {

    return (
        <div className="d-flex pending-page">
            <ToastContainer />
            <Sidenav />
            <PendingList />
        </div>
    )
}
export default PendingPage;