import Spinner from 'react-bootstrap/Spinner';

function Loader ({ text }) {
    return (
        <div className="d-flex justify-content-center align-items-center spinner mt-5">
            <Spinner animation="border" variant="info"/>
        <span className="p-3 fw-bold">{ text }</span>
    </div>
    );
}

export default Loader;