import { USER_AUTHENTICATE } from "../components/user-manager";
import { useState } from "react";
import { ToastContainer } from "react-toastify";

function LoginPage() {
    const { USER_LOGIN } = USER_AUTHENTICATE();
    const [loader, setLoader] = useState("Login");
    const [state, setState] = useState({
        email: '',
        password: '',
    });

    //Function for Login event
    const handleLogin = async(event) => {
        event.preventDefault();
        setLoader("Logging In");
        await USER_LOGIN(state.email, state.password);
        setState((prevState) => ({
            ...prevState,
            password: '',
        }));
        setLoader("Login");
    };

    //Function for password and email change event
    const handleChange = (event) => {
        const field = event.target.name;
        setState((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    return (
        <div className="loginBackground d-flex justify-content-center align-items-center">
        <ToastContainer />
            <form onSubmit={ handleLogin } className="d-flex row align-content-center login-container justify-content-center bg-white p-5 rounded-5">
            <h1 className="text-center fw-semibold mb-4">Login</h1>
            <p className="text-center login-p">Sign in to your account</p>
            <hr />
                <input type="email" 
                       name="email"
                       className="login-textbox border-0 mt-3 rounded-3"
                       placeholder="Email"
                       id="email"
                       value={ state.email }
                       onChange={ handleChange } 
                       required/>

                <input type="password" 
                       name="password"
                       maxLength={ 16 }
                       className="login-textbox border-0 mt-4 rounded-3"
                       placeholder="Password"
                       id="password"
                       value={ state.password }
                       onChange={ handleChange } 
                       required/>
                
                <p className="text-center mt-4 login-p">Want to register your vehicle? <a className="login-a" href="/register-vehicle">Register Here</a></p>

                <button type="submit"
                    className="login-button border-0 mt-2 rounded-5 text-white"
                    disabled={loader === "Logging In"}>
                        {loader}
                </button>

            </form>
        </div>
    )
}

export default LoginPage;