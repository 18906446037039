import { useState } from "react";
import { ToastContainer } from "react-toastify";
import UserRegisterForm from "../forms/user-form";
import VehicleRegisterForm from "../forms/vehicle-form";
import CameraRegisterForm from "../forms/camera-form";
import Sidenav from "../templates/sidenav-template";

function RegisterPage() {
    const [registerType, setRegisterType] = useState('');

    // Function for input change event
    const handleChange = (event) => {
        setRegisterType(event.target.value);
    };

    return (
        <div className="d-flex register-page">
            <ToastContainer />
            <Sidenav />
            <div className="register-container">
                <div className="register-content d-flex pt-3">
                    <h3 className="fw-semibold">Register Type :</h3>
                    <select name="User Type" value={registerType} 
                            onChange={ handleChange } className="select-box border-0 rounded-1 ms-4 ps-2"
                            required>
                        <option value="" defaultValue disabled>Select Type</option>
                        <option value="User">User</option>
                        <option value="Vehicle">Vehicle</option>
                        <option value="Camera">Camera</option>
                    </select>
                </div>
                <hr />

                <div className="d-flex justify-content-evenly register-form-container">
                    {registerType === "User" && <UserRegisterForm />}
                    {registerType === "Vehicle" && <VehicleRegisterForm />}
                    {registerType === "Camera" && <CameraRegisterForm />}

                    {registerType === "" && 
                        <p className="mt-5">Please select a type to register</p>
                    }
                </div>
            </div>
        </div >
    )
}

export default RegisterPage;