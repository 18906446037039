import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { useLocation } from 'react-router-dom';
import { USER_AUTHENTICATE } from "../components/user-manager";
import { Button } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';

function Sidenav () {
    const { USER_LOGOUT } = USER_AUTHENTICATE();
    const user_type = localStorage.getItem("user_type");
    const [loader, setLoader] = useState("Logout");
    const [sidebarState, setSidebarState] = useState(false);
    const token = localStorage.getItem("token");
    const location = useLocation();

    //Function for Login event
    const handleLogout = async(event) => {
        event.preventDefault();
        setLoader("Logging Out...");
        await USER_LOGOUT(token);
        setLoader("Logout");
    };

    const handleClick = (path) => {
        localStorage.setItem("current_path", path);
    }

    useEffect(() => {
        const current_path = localStorage.getItem("current_path");

        if(current_path && location.pathname === "/") {
            window.location.replace(current_path);
        }
    }, [location.pathname]);

    //Function to check if the current path matches the href
    const is_active = (path) => location.pathname === path ? "active-link" : "nav-button";

    const toggleHamburger = () => {
        setSidebarState(!sidebarState);
    }

    return(
        <div className="sidenav-background">
            <Button variant="dark" onClick={ toggleHamburger } className="hamburger-button d-md-none">
                <FaBars size={16}/>
            </Button>

            <div className={`d-flex sidebar ${sidebarState ? 'open':'closed'}`}>
                <div className="d-flex column justify-content-between flex-column">
                    <h3 className="navbar-logo fw-bold text-white text-center pt-3">LNU-VMS</h3>
                    <hr className="white-hr" />
                    <Nav defaultActiveKey="/home" className="flex-column">
                        <Nav.Link href="/home" 
                                  className={`rounded-start ${is_active("/home")}`}
                                  onClick={() => handleClick("/home")}>Live Feed</Nav.Link>
                        <Nav.Link href="/logs" 
                                  className={`rounded-start ${is_active("/logs")}`}>Vehicle Logs</Nav.Link>
                        {user_type === "Admin" && (
                            <div>
                                <Nav.Link href="/register-list" 
                                          className={`rounded-start ${is_active("/register-list")}`}>Register List</Nav.Link>
                                <Nav.Link href="/register" 
                                          className={`rounded-start ${is_active("/register")}`}>Register</Nav.Link>
                                <Nav.Link href="/pending-requests" 
                                          className={`rounded-start ${is_active("/pending-requests")}`}>Pending Request</Nav.Link>
                                <Nav.Link href="/archive-logs"
                                          className={`rounded-start ${is_active("/archive-logs")}`}>Archive Logs</Nav.Link>
                            </div>
                        )}
                    </Nav>
                    <Button variant="light" className="mt-auto mb-4 w-75 ms-4" onClick={ handleLogout } disabled = {loader === "Logging Out..."}> 
                        { loader }
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Sidenav;