import { Modal, Button } from 'react-bootstrap';
import { useState } from 'react';

function ConfirmationModal({ onDelete, onClose }) {
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        onClose();
    };

    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header>
                <Modal.Title className="text-white fw-bold">Delete Confirmation</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Are you sure??</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="info" onClick={ handleClose }>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => { onDelete(); } }>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModal;