import { USER_AUTHENTICATE } from "../components/user-manager";
import { TOAST_FAIL } from "../components/toasters";
import { useState } from "react";

function UserRegisterForm() {
    const { USER_REGISTER } = USER_AUTHENTICATE();
    const [loader, setLoader] = useState("Register");
    const [state, setState] = useState({
        id_number: '',
        username: '',
        email: '',
        password: '',
        confirmpassword: '',
        usertype:'',
    });

    //Function for password and email change event
    const handleChange = (event) => {
        const field = event.target.name;
        setState((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    //Function for Register Event
    const handleRegister = async(event) => {
        event.preventDefault();
        setLoader("Registering...");
        if (state.password !== event.target.confirmpassword.value) {
            TOAST_FAIL("Password do not match!");
            setLoader("Register");
            setState((prevState) => ({
                ...prevState,
                password: '',
                confirmpassword: '',
            }));
            return;
        }
        await USER_REGISTER(
            state.id_number, 
            state.username, 
            state.email, 
            state.password, 
            state.usertype);
        setState((prevState) => ({
            ...prevState,
            id_number: '',
            username: '',
            email: '',
            password: '',
            confirmpassword: '',
            usertype: '',
        }));
        setLoader("Register");
    };

    return (
        <div>
            <form onSubmit={ handleRegister }>
            <h3 className="text-center mb-4 fw-bold">User Register</h3>
                    <input type="number"
                        name="id_number"
                        placeholder="ID number"
                        id="id_number"
                        className="register-textbox border-0 mt-3 rounded-1 ps-3"
                        value={state.id_number}
                        onChange={handleChange}
                        maxLength={10}
                        onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                        required />
                    <br />
                    <input type="text"
                        name="username"
                        className="register-textbox border-0 mt-3 rounded-1 ps-3"
                        placeholder="Username"
                        id="username"
                        maxLength={ 16 }
                        value={state.username}
                        onChange={handleChange}
                        required />
                    <br />
                    <input type="email"
                        name="email"
                        className="register-textbox border-0 mt-3 rounded-1 ps-3"
                        placeholder="Email"
                        id="email"
                        value={state.email}
                        onChange={handleChange}
                        required />
                    <br />
                    <input type="password"
                        name="password"
                        className="register-textbox border-0 mt-3 rounded-1 ps-3"
                        placeholder="Password"
                        id="password"
                        maxLength={ 16 }
                        value={state.password}
                        onChange={handleChange}
                        required />
                    <br />
                    <input type="password"
                        name="confirmpassword"
                        className="register-textbox border-0 mt-3 rounded-1 ps-3"
                        placeholder="Confirm Password"
                        id="confirmpassword"
                        maxLength={ 16 }
                        value={state.confirmpassword}
                        onChange={handleChange}
                        required />
                    <br />
                    <select name="usertype" value={state.usertype} onChange={handleChange} required 
                        className="register-textbox border-0 mt-3 rounded-3 ps-3">
                        <option value="" defaultValue disabled>User Type</option>
                        <option value="Admin">Admin</option>
                        <option value="Guard">Security Guard</option>
                    </select>
                    <br />
                    <hr/>
                    <button type="submit" 
                            className="register-button border-0 mt-2 w-100 rounded-5 text-white"
                            disabled={loader === "Registering..."}>{ loader }</button>
            </form>
        </div>
    )
}

export default UserRegisterForm;