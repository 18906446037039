import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ToastContainer } from "react-toastify";
import UsersList from '../data-tables/users-table';
import VehiclesList from '../data-tables/vehicles-table';
import CameraList from '../data-tables/cameras-table';
import Sidenav from "../templates/sidenav-template";

function RegisterListPage() {

    return (
        <div className="d-flex register-list-page">
            <ToastContainer />
            <Sidenav />
            <div className="register-list">
                <Tabs defaultActiveKey="users" fill>
                    <Tab eventKey="users" title="Users">
                        <div className="userTable">
                            <UsersList />
                        </div>
                    </Tab>
                    <Tab eventKey="vehicles" title="Vehicles">
                        <VehiclesList />
                    </Tab>
                    <Tab eventKey="cameras" title="Cameras">
                        <CameraList />
                    </Tab>
                </Tabs>
            </div>

        </div>
    )
}

export default RegisterListPage;