import { useState } from "react";
import { VEHICLE_MANAGER } from "../components/vehicle-manager";

function VehicleRegisterForm() {
    const { VEHICLE_REGISTER } = VEHICLE_MANAGER();
    const [loader, setLoader] = useState("Register");
    const [state, setState] = useState({
        plate_number: '',
        username: '',
        identity: '',
        email: '',
        contact_number: '',
    });

    //Function for password and email change event
    const handleChange = (event) => {
        const field = event.target.name;
        setState((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    //Function for Register event
    const handleRegister = async(event) => {
        event.preventDefault();
        setLoader("Registering...");
        await VEHICLE_REGISTER(
            state.plate_number,
            state.username,
            state.identity,
            state.email,
            state.contact_number,
        );
        setState((prevState) => ({
            ...prevState,
            plate_number: '',
            username: '',
            identity: '',
            email: '',
            contact_number: '',
        }));
        setLoader("Register");
    };

    return (
        <div>
            <form onSubmit={ handleRegister }>
            <h3 className="text-center mb-4 fw-bold">Vehicle Register</h3>
                <input type="text"
                    name="plate_number"
                    className="register-textbox border-0 mt-3 rounded-1 ps-3"
                    placeholder="Plate Number"
                    id="plate_number"
                    value={state.plate_number}
                    onChange={handleChange}
                    required />
                <br />
                <input type="text"
                    name="username"
                    className="register-textbox border-0 mt-3 rounded-1 ps-3"
                    placeholder="Owner Name"
                    id="username"
                    value={state.username}
                    onChange={handleChange}
                    required />
                <br />
                <input type="text"
                    name="identity"
                    className="register-textbox border-0 mt-3 rounded-1 ps-3"
                    placeholder="Identity"
                    id="identity"
                    value={state.identity}
                    onChange={handleChange}
                    required />
                <br />
                <input type="email"
                    name="email"
                    className="register-textbox border-0 mt-3 rounded-1 ps-3"
                    placeholder="Email"
                    id="email"
                    value={state.email}
                    onChange={handleChange}
                    required />
                <br />
                <input type="number"
                    name="contact_number"
                    className="register-textbox border-0 mt-3 rounded-1 ps-3"
                    placeholder="Contact Number"
                    id="contact_number"
                    value={state.contact_number}
                    onChange={handleChange}
                    required />
                <br />
                <hr/>
                <button type="submit" 
                        className="register-button border-0 mt-2 w-100 rounded-5 text-white"
                        disabled={loader === "Registering..."}>{ loader }</button>
            </form>
        </div>
    )
}

export default VehicleRegisterForm;