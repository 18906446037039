import Sidenav from "../templates/sidenav-template";
import { ToastContainer } from "react-toastify";
import ArchiveLogList from "../data-tables/archive-logs-table";

function ArchivePage() {
    
    return(
        <div className="d-flex column">
            <ToastContainer />
            <Sidenav />
            <ArchiveLogList />
        </div>
    )
}

export default ArchivePage;